<template>
  <q-dialog
    v-model="model"
    transition-show="flip-down"
    transition-hide="flip-up"
    persistent
  >
    <q-card>
      <q-bar>
        <h3 class="text-white text-uppercase">DELETE {{ name }}</h3>
        <q-space />
        <q-btn
          dense
          flat
          icon="close"
          color="white"
          v-close-popup
          @click="close()"
        >
        </q-btn>
      </q-bar>

      <q-card-section>
        <h3 class="q-mt-none text-uppercase page-subtitle text-weight-medium">
          Confirm The Action
        </h3>
        <q-btn
          class="float-right q-ml-lg"
          color="primary"
          label="Delete"
          @click="deleteItem()"
        />
        <q-btn
          class="float-right"
          outline
          color="primary"
          label="Cancel"
          @click="close()"
          v-close-popup
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    model: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      openModal: false
    };
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    async deleteItem() {
      try {
        await this.$http[this.endpoint].delete(this.data.id);
        this.$emit("remove", this.data);
        this.$q.notify({
          color: "positive",
          icon: "done",
          position: "top",
          message: `${this.name.toLocaleUpperCase()} Deleted Successfully!`,
          timeout: 3000
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          icon: "warning",
          position: "top",
          message: err.message,
          timeout: 1000
        });
      }
      this.close();
    }
  }
};
</script>
