<template>
  <div class="q-pb-lg customers">
    <div>
      <div class="flex row q-gutter-md justify-end q-my-md items-center">
        <q-input
          class="type-name col-12 col-md-2"
          v-model="search"
          placeholder="Search for Customer"
          @input="typeaheadDelay(getData)"
        >
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </div>
    <q-table
      ref="table"
      :grid="$q.screen.xs"
      :data="data"
      :columns="columns"
      row-key="id"
      borderless
      binary-state-sort
      color="#797979"
      hide-pagination
      :loading="loading"
    >
      <template v-slot:bottom>
        <pagination
          @change="getData"
          :initialPagination="initialPagination"
          :limits="limits"
        />
      </template>
      <template v-slot:loading>
        <q-inner-loading showing color="primary" />
      </template>
      <template v-slot:body-cell-customer="props">
        <td :props="props">
          <q-item class="q-pa-none">
            <q-item-section avatar>
              <img class="customer-avatar" :src="getAvatar(props.row)" alt="" />
            </q-item-section>
            <q-item-section>
              {{ props.row.name }}
            </q-item-section>
          </q-item>
        </td>
      </template>
    </q-table>
  </div>
</template>
<script>
import { limits } from "@/utils/filter";
import { typeaheadDelay } from "@/helpers/typeaheadDelay";
import Pagination from "@/components/Pagination.vue";
export default {
  components: { Pagination },
  data() {
    return {
      base_url: "",
      typeaheadDelay: typeaheadDelay,
      limits: limits,
      isHidden: false,
      search: null,
      loading: false,
      data: [],
      columns: [
        {
          name: "customer",
          align: "left",
          label: "Customer",
        },

        {
          name: "phone",
          align: "left",
          label: "Mobile Phone",
          field: "phone",
        },
        {
          name: "email",
          align: "left",
          label: "Email",
          field: "email",
        },
      ],
      initialPagination: {
        page: 1,
        rowsPerPage: 8,
        rowsNumber: 0,
        pageCount: 1,
        total: 1,
      },
    };
  },
  mounted() {
    this.getData();
    this.base_url = process.env.VUE_APP_API_URL;
  },
  methods: {
    getAvatar(customer) {
      return customer.avatar
        ? this.base_url + customer.avatar.thumbnail
        : require("@/assets/placeholder.png");
    },
    async getData() {
      this.loading = true;
      const params = {
        page: this.initialPagination.page,
        limit: this.initialPagination.rowsPerPage,
        search: this.search,
      };
      const res = await this.$http.customer.get(params);
      this.data = res.data.data;
      this.initialPagination.rowsNumber = res.data.meta.totalItems;
      this.initialPagination.pageCount = res.data.meta.totalPages;
      this.loading = false;
    },
  },
};
</script>
