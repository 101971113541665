import store from "@/store";
import Login from "@/views/Login.vue";
import NotFound from "@/views/NotFound.vue";
import Profile from "@/views/Profile.vue";
import Customers from "@/views/Customers.vue";
import Phrases from "@/views/Phrases/List.vue";
import Phrase from "@/views/Phrases/Manage.vue";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Phrases",
    redirect: "/phrases"
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
    meta: { authorizedRoles: [], requiresAuth: true }
  },
  {
    path: "/phrases",
    name: "Phrases",
    component: Phrases,
    meta: { authorizedRoles: [], requiresAuth: true }
  },
  {
    path: "/add-phrase",
    name: "Add Phrase",
    component: Phrase,
    meta: { authorizedRoles: [], requiresAuth: true }
  },
  {
    path: "/phrase/:id",
    name: "Phrase",
    component: Phrase,
    meta: { authorizedRoles: [], requiresAuth: true }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      authorizedRoles: ["Admin"],
      params: {},
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { authorizedRoles: [], requiresAuth: false }
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
    meta: { authorizedRoles: ["Admin"], requiresAuth: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.API_URL,
  routes
});

router.beforeEach((to, _from, next) => {
  let isLoggedIn = store.getters.accessToken;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next({ path: "/login" });
    }
  }
  if (to.matched.some(record => !record.meta.requiresAuth)) {
    if (isLoggedIn && to.path !== "/") {
      next({ path: "/" });
    }
  }
  next();
});

export default router;
