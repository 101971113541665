<template>
  <div class="row flex flex-center">
    <q-select
      bg-color="white"
      borderless
      v-model="initialPagination.rowsPerPage"
      :options="limits"
      @input="getData()"
    >
    </q-select>
    <div class="q-px-lg flex flex-center">
      <q-btn
        :disable="initialPagination.page === 1"
        flat
        @click="changePagination('prev')"
        icon="chevron_left"
        class="arrow-btn"
        color="accent"
      ></q-btn>
      <q-pagination
        v-model="initialPagination.page"
        :max="initialPagination.pageCount"
        borderless
        flat
        active-color="black"
        color="accent"
        @click="getData"
      />
      <q-btn
        :disable="initialPagination.page === initialPagination.pageCount"
        flat
        color="accent"
        class="arrow-btn"
        icon-right="navigate_next"
        @click="changePagination('next')"
      ></q-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    initialPagination: {
      type: Object,
      required: true
    },
    limits: {
      type: Array,
      required: true
    }
  },
  methods: {
    changePagination(page) {
      if (page === "prev" && this.initialPagination.page > 1) {
        this.initialPagination.page--;
      } else if (
        page === "next" &&
        this.initialPagination.page < this.initialPagination.pageCount
      ) {
        this.initialPagination.page++;
      }
      this.getData();
    },
    getData() {
      this.$emit("change", this.initialPagination);
    }
  }
};
</script>
