<template>
  <q-layout view="lHh Lpr lFf" v-if="$store.getters.accessToken">
    <q-header>
      <q-toolbar class="text-dark d-flex items-center">
        <h2 class="q-ma-none page-header">{{ $route.name }}</h2>

        <q-toolbar-title class="mobile-only">
          <q-btn flat @click="leftDrawerOpen = !leftDrawerOpen">
            <img src="@/assets/sidebar.svg" alt="Export" />
          </q-btn>
        </q-toolbar-title>
        <q-space class="desktop-only"></q-space>

        <div class="profile row items-center cursor-pointer">
          <img src="@/assets/profile.svg" alt="Profile" />
          <div class="q-ml-md">
            <div class="row">
              <div class="full-name">
                {{
                  $store.state.user.firstName + " " + $store.state.user.lastName
                }}
              </div>
              <q-icon name="expand_more" />
            </div>
            <span class="email">{{ $store.state.user.email }}</span>
          </div>

          <q-menu
            :offset="[-15, 0]"
            transition-show="flip-down"
            transition-hide="flip-up"
            content-class="profile-dropdown"
            auto-close
          >
            <div class="arrow-up"></div>
            <div class="row no-wrap column">
              <div class="menu-header q-px-sm">
                <div class="text-white">
                  {{
                    $store.state.user.firstName +
                      " " +
                      $store.state.user.lastName
                  }}
                </div>
                <span class="text-white">{{ $store.state.user.email }}</span>
              </div>
              <q-list>
                <q-item
                  clickable
                  tag="a"
                  to="/profile"
                  class="profile-link"
                  @click.native="showChildren = false"
                >
                  <q-item-section avatar>
                    <svg-profile></svg-profile>
                  </q-item-section>
                  <q-item-section>Profile</q-item-section>
                </q-item>
                <q-item clickable tag="div" @click="logout()">
                  <q-item-section avatar class="q-pt-xs">
                    <svg-turnoff></svg-turnoff>
                  </q-item-section>
                  <q-item-section>Sign Out</q-item-section>
                </q-item>
              </q-list>
            </div>
          </q-menu>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      :mini="!leftDrawerOpen || miniState"
      @click.capture="drawerClick"
      :width="300"
      :breakpoint="500"
      show-if-above
    >
      <div @click="$router.push('/')" class="info-block text-center">
        <img src="@/assets/logo.png" class="q-mb-sm" alt="Logo" />
      </div>
      <q-list class="drawer-nav">
        <q-item clickable tag="a" to="/phrases">
          <div class="row">
            <q-item-section avatar>
              <svg-phrases></svg-phrases>
            </q-item-section>
            <q-item-section>
              <q-item-label>
                Phrases
              </q-item-label>
            </q-item-section>
          </div>
        </q-item>
        <q-item clickable tag="a" to="/customers">
          <div class="row">
            <q-item-section avatar>
              <svg-customers></svg-customers>
            </q-item-section>
            <q-item-section>
              <q-item-label>
                Customers
              </q-item-label>
            </q-item-section>
          </div>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view class="q-px-lg"></router-view>
    </q-page-container>
  </q-layout>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import SvgProfile from "@/components/svg/profile";
import SvgTurnoff from "@/components/svg/turnoff";
import SvgCustomers from "@/components/svg/customers";
import SvgPhrases from "@/components/svg/phrases";
export default {
  name: "LayoutDefault",
  components: {
    SvgCustomers,
    SvgTurnoff,
    SvgProfile,
    SvgPhrases
  },
  data() {
    return {
      leftDrawerOpen: !this.$q.platform.is.mobile,
      showChildren: false,
      miniState: false
    };
  },
  methods: {
    logout() {
      this.showChildren = false;
      this.$store.commit("USER_LOGOUT");
      this.$router.push("/login");
    },
    drawerClick(e) {
      if (this.miniState.value) {
        this.miniState.value = false;
        e.stopPropagation();
      }
    }
  },
  created() {}
};
</script>

<style></style>
