import axios from "axios";
import store from "../store";
import headers from "./ApiHeaders";
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000
});

instance.interceptors.request.use(config => {
  config.headers = headers();
  return config;
});
instance.interceptors.response.use(
  response => response,
  error => {
    if (error.message === "Unauthorized") {
      this.$store.commit("USER_LOGOUT");
      this.$router.push("/login");
    }

    throw error;
  }
);
const service = {
  auth: {
    login(form) {
      return instance({
        url: "auth/admin/login",
        method: "post",
        data: form
      });
    },

    changePassword(data) {
      return instance({
        url: `admin/change-password`,
        method: "put",
        data
      });
    }
  },
  user: {
    update(data) {
      return instance({
        url: `admin/${store.getters.user.id}`,
        method: "patch",
        data
      });
    },
    get() {
      return instance({
        url: `admin/${store.getters.user.id}`,
        method: "get"
      });
    }
  },
  customer: {
    get(params) {
      return instance({
        url: `customer`,
        method: "get",
        params
      });
    }
  },
  phrases: {
    get(params) {
      return instance({
        url: `phrases`,
        method: "get",
        params
      });
    },
    getById(id) {
      return instance({
        url: `phrases/${id}`,
        method: "get"
      });
    },
    create(data) {
      return instance({
        url: "phrases",
        method: "post",
        data
      });
    },
    edit(data) {
      return instance({
        url: `phrases/${data.id}`,
        method: "patch",
        data
      });
    },
    getAuthors() {
      return instance({
        url: `phrases/authors`,
        method: "get"
      });
    },
    delete(id) {
      return instance({
        url: `phrases/${id}`,
        method: "delete"
      });
    },
    createAuthor(data) {
      return instance({
        url: "phrases/author",
        method: "post",
        data
      });
    }
  },
  media: {
    create(form) {
      return instance({
        url: "media",
        method: "post",
        data: form
      });
    }
  }
};
export default service;
