<template>
  <q-form @submit="login" class="login-form" ref="loginForm">
    <div class="form-background">
      <div class="form-heading">
        <h1 class="text-uppercase form-header">Sign In</h1>
        <h3 class="q-mb-none">Welcome</h3>
        <span class="text-body2">Please Sign In</span>
      </div>
      <div class="form-body">
        <div class="form-group">
          <q-input
            v-model="form.email"
            class="q-mt-md form-input text-body2"
            rounded
            outlined
            type="email"
            placeholder="Email"
            :rules="[required, emailValidation]"
          />
        </div>
        <div class="form-group">
          <q-input
            v-model="form.password"
            class=" form-input text-body2"
            outlined
            rounded
            :type="isPwd ? 'password' : 'text'"
            :rules="[required, isValidPassword]"
            placeholder="Password"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </div>
        <div class="form-actions row justify-end items-center">
          <q-btn
            flat
            rounded
            class="btn-sign-in bg-secondary text-white text-weight-medium"
            label="Sign In"
            type="submit"
          />
        </div>
      </div>
    </div>
  </q-form>
</template>

<script>
import { required, email, password } from "../utils/validations";
export default {
  data() {
    return {
      emailValidation: email,
      required: required,
      isValidPassword: password,
      isPwd: true,
      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    async login() {
      const success = await this.$refs.loginForm.validate();
      if (success) {
        try {
          await this.$store.dispatch("login", this.form);
          location.href = "/phrases";
        } catch (err) {
          this.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: err.message,
            timeout: 1000
          });
        }
      }
    }
  }
};
</script>
