<template>
  <div class="profile pos-relative">
    <div class="q-pa-md container">
      <div class="spinner-overlay" v-if="loading">
        <q-spinner color="primary" size="3em" />
      </div>

      <div v-else>
        <q-form @submit="changeProfile" ref="profileForm" class="q-gutter-md">
          <h3 class="page-subtitle">Manage My Profile</h3>
          <div class="line"></div>
          <q-input
            v-model="profile.firstName"
            :rules="[required]"
            label="First Name"
          />
          <q-input
            v-model="profile.lastName"
            :rules="[required]"
            label="Last Name"
          />
          <q-input
            v-model="profile.email"
            :rules="[required]"
            label="Email"
            disable
            type="email"
          />
          <div class="row justify-end items-center">
            <q-btn
              color="primary"
              class="save-btn"
              text-color="white"
              label="UPDATE"
              type="submit"
            />
          </div>
        </q-form>
        <q-form
          @submit="changePassword"
          ref="changePasswordForm"
          class="q-gutter-md"
        >
          <h3 class="page-subtitle">Change Password</h3>
          <div class="line"></div>
          <q-input
            v-model="passwordForm.oldPassword"
            class="q-mt-md"
            name="current-password"
            :rules="[required]"
            :type="isPwd1 ? 'password' : 'text'"
            placeholder="Current Password"
          >
            <template v-slot:prepend>
              <img src="@/assets/lock.svg" alt="Lock" />
            </template>
            <template v-slot:append>
              <q-icon
                :name="isPwd1 ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd1 = !isPwd1"
              />
            </template>
          </q-input>
          <q-input
            v-model="passwordForm.newPassword"
            :rules="[required, isValidPassword, confirmPass]"
            :type="isPwd2 ? 'password' : 'text'"
            placeholder="New Password"
            ref="newPassword"
            name="new-password"
          >
            <template v-slot:prepend>
              <img src="@/assets/lock.svg" alt="Lock" />
            </template>
            <template v-slot:append>
              <q-icon
                :name="isPwd2 ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd2 = !isPwd2"
              />
            </template>
          </q-input>
          <q-input
            v-model="confirmNewPassword"
            :rules="[required, isValidPassword, confirmPass]"
            :type="isPwd3 ? 'password' : 'text'"
            placeholder="Confirm New Password"
            ref="confirmPassword"
            name="confirm-password"
          >
            <template v-slot:prepend>
              <img src="@/assets/lock.svg" alt="Lock" />
            </template>
            <template v-slot:append>
              <q-icon
                :name="isPwd3 ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd3 = !isPwd3"
              />
            </template>
          </q-input>
          <div class="row justify-end items-center">
            <q-btn
              color="primary"
              class="save-btn"
              text-color="white"
              label="UPDATE"
              type="submit"
            />
          </div>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, password, ConfirmPWD, email } from "@/utils/validations";
export default {
  data() {
    return {
      loading: false,
      required: required,
      isValidPassword: password,
      confirmPass: () => {},
      isValidEmail: email,
      isPwd1: true,
      isPwd2: true,
      confirmNewPassword: "",
      isPwd3: true,
      passwordForm: {
        oldPassword: "",
        newPassword: ""
      },
      profile: {
        firstName: "",
        lastName: "",
        phone: null,
        email: null
      }
    };
  },
  async created() {
    this.loading = true;
    this.confirmPass = ConfirmPWD.bind(this);
    const res = await this.$http.user.get();
    this.profile = res.data;
    this.loading = false;
  },
  methods: {
    getDate(dt) {
      const date = new Date(dt);
      return (
        date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
      );
    },
    async changePassword() {
      const success = await this.$refs.changePasswordForm.validate();
      if (success) {
        try {
          await this.$http.auth.changePassword(this.passwordForm);
          this.$q.notify({
            color: "positive",
            icon: "done",
            position: "top",
            message: "Password Changed Successfully",
            timeout: 1000
          });
          location.reload();
        } catch (err) {
          this.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: err.message,
            timeout: 1000
          });
        }
      }
    },
    async changeProfile() {
      const success = await this.$refs.profileForm.validate();
      if (success) {
        const profile = {
          firstName: this.profile.firstName,
          lastName: this.profile.lastName
        };
        try {
          await this.$http.user.update(profile);
          this.$q.notify({
            color: "positive",
            icon: "done",
            position: "top",
            message: "Profile Changed Successfully",
            timeout: 1000
          });
        } catch (err) {
          this.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: err.message,
            timeout: 1000
          });
        }
      }
    }
  }
};
</script>
