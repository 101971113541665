<template>
  <div class="add-phrase">
    <div class="q-pa-md container">
      <div class="spinner-overlay" v-if="loading">
        <q-spinner color="primary" size="3em" />
      </div>
      <q-form
        v-else
        @submit="$route.params.id ? edit() : add()"
        ref="phraseForm"
        class="q-gutter-md"
      >
        <q-input
          class="type-name col-12"
          v-model="phrase.phrase"
          :rules="[required]"
          type="textarea"
          placeholder="Phrase"
        >
        </q-input>
        <q-select
          filled
          v-model="author"
          use-input
          hide-selected
          fill-input
          @filter="filterFn"
          input-debounce="0"
          option-label="name"
          option-value="id"
          :options="data"
          emit-value
          :rules="[required]"
          placeholder="Author"
          map-options
          loading
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                No results
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:append>
            <q-btn round dense flat icon="add" @click="createAuthor" />
          </template>
        </q-select>
        <q-select
          dropdown-icon="keyboard_arrow_down"
          class="col-12"
          :options="dayTimes"
          label="Choose Day Time"
          :rules="[required]"
          v-model="phrase.dayTime"
        >
        </q-select>
        <q-input
          class="type-name col-12 q-my-md"
          v-model="phrase.week"
          type="number"
          placeholder="Week"
        >
        </q-input>
        <q-btn
          color="primary"
          class="save-btn q-my-xl"
          text-color="white"
          label="SAVE"
          type="submit"
        />
      </q-form>
    </div>
  </div>
</template>
<script>
import { required } from "@/utils/validations";
export default {
  data() {
    return {
      required: required,
      phrase: {
        phrase: null,
        dayTime: null,
        authorId: null,
        week: null
      },
      author: "",
      authors: [],
      selected: [],
      data: [],
      loading: false,
      load: false,
      dayTimes: ["Morning", "Afternoon", "Evening"]
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.loading = true;
      const res = await this.$http.phrases.getById(this.$route.params.id);
      this.phrase = res.data;
      this.author = res.data.author;
      this.loading = false;
    }
    await this.getAuthors();
  },
  methods: {
    filterFn(val, update) {
      if (val) {
        this.author = val;
        update(() => {
          const needle = val.toLowerCase();
          this.data = this.authors.filter(
            v => v.name.toLowerCase().indexOf(needle) > -1
          );
        });
      }
    },
    async getAuthors() {
      const authors = await this.$http.phrases.getAuthors();
      this.authors = authors.data.data;
    },
    async createAuthor() {
      if (this.author) {
        const author = await this.$http.phrases.createAuthor({
          name: this.author
        });
        this.phrase.authorId = author.data.id;
        this.author = author.data;
        await this.getAuthors();
      }
    },
    async add() {
      const success = await this.$refs.phraseForm.validate();
      if (success) {
        this.phrase.authorId = this.author.id ? this.author.id : this.author;
        this.phrase.week = +this.phrase.week;
        try {
          await this.$http.phrases.create(this.phrase);
          this.$q.notify({
            color: "positive",
            icon: "done",
            position: "top",
            message: "Phrase Created Successfully!",
            timeout: 3000
          });
          this.$router.push("/phrases");
        } catch (err) {
          this.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: err.message || "Error",
            timeout: 1000
          });
        }
      }
    },
    async edit() {
      const success = await this.$refs.phraseForm.validate();
      if (success) {
        this.phrase.week = +this.phrase.week;
        delete this.phrase.author;
        this.phrase.authorId = this.author.id ? this.author.id : this.author;
        try {
          await this.$http.phrases.edit(this.phrase);
          this.$q.notify({
            color: "positive",
            icon: "done",
            position: "top",
            message: "Phrase Updated Successfully!",
            timeout: 3000
          });
          this.$router.push("/phrases");
        } catch (err) {
          this.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: err.message || "Error",
            timeout: 1000
          });
        }
      }
    }
  }
};
</script>
