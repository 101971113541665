import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import "./styles/global.scss";
import ApiService from "./services/ApiService";

Vue.config.productionTip = false;
Vue.prototype.$http = ApiService;

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
