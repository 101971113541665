const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
const phonePattern = /^[0-9]+$/;
export const email = val =>
  emailPattern.test(val) || "Please enter a valid email address";
export const password = pass => {
  return pass.length > 6 || "Please enter a valid password";
};
export const required = val => !!val || "Field is required";
export const phone = val =>
  phonePattern.test(val) || "Please enter a valid phone number";
export const ConfirmPWD = function() {
  const disableValidation = !this.$refs.confirmPassword.value;

  if (disableValidation) return true;

  const validity =
    this.$refs.confirmPassword.value === this.$refs.newPassword.value ||
    "Different passwords";

  if (validity) {
    this.$refs.newPassword.resetValidation();
    this.$refs.confirmPassword.resetValidation();
  }

  return validity;
};
