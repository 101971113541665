import { render, staticRenderFns } from "./List.vue?vue&type=template&id=0c8e07df"
import script from "./List.vue?vue&type=script&lang=js"
export * from "./List.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QInput,QIcon,QTable,QInnerLoading,QBtn,QTooltip});
