<template>
  <div class="login-page">
    <div class="background row">
      <div class="bg-left"></div>
      <div class="divider"></div>
      <div class="bg-right"></div>
    </div>
    <div class="overlay">
      <login-form @open-modal="forgetPassModal = true"></login-form>
    </div>
    <q-dialog
      v-model="forgetPassModal"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white">Forgot your Password?</h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup> </q-btn>
        </q-bar>

        <q-card-section class="text-center">
          <h2 class="q-mb-none">Contact Your Admin</h2>

          <h3 class="q-mt-none">To Reset Password</h3>
          <q-btn color="primary" label="Ok" v-close-popup />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm";
export default {
  components: {
    LoginForm
  },
  data() {
    return {
      forgetPassModal: false
    };
  }
};
</script>
