export const limits = [5, 8, 10, 15, 20];
export const setSearch = val => (val ? `search=${val}` : null);
export const setPage = val => (val ? `page=${val}` : null);
export const setLimit = val => (val ? `limit=${val}` : null);
export const filterByPartnerId = val =>
  val ? `filter.partner.id=${val}` : null;

export const getQuery = val => {
  const queries = [];
  val.map(el => {
    if (!el.value) {
      val.splice(val.indexOf(el), 1);
    }
  });
  val.forEach(item => queries.push(item.method(item.value)));

  return queries.join("&");
};
