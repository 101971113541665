<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16675 6.4165C8.65286 6.4165 9.06605 6.24637 9.40633 5.90609C9.74661 5.56581 9.91675 5.15262 9.91675 4.6665C9.91675 4.18039 9.74661 3.7672 9.40633 3.42692C9.06605 3.08664 8.65286 2.9165 8.16675 2.9165C7.68064 2.9165 7.26744 3.08664 6.92717 3.42692C6.58689 3.7672 6.41675 4.18039 6.41675 4.6665C6.41675 5.15262 6.58689 5.56581 6.92717 5.90609C7.26744 6.24637 7.68064 6.4165 8.16675 6.4165ZM4.66675 10.4998C4.34591 10.4998 4.07136 10.3857 3.84308 10.1574C3.61441 9.92875 3.50008 9.654 3.50008 9.33317V2.33317C3.50008 2.01234 3.61441 1.73759 3.84308 1.50892C4.07136 1.28064 4.34591 1.1665 4.66675 1.1665H11.6667C11.9876 1.1665 12.2623 1.28064 12.491 1.50892C12.7193 1.73759 12.8334 2.01234 12.8334 2.33317V9.33317C12.8334 9.654 12.7193 9.92875 12.491 10.1574C12.2623 10.3857 11.9876 10.4998 11.6667 10.4998H4.66675ZM2.33341 12.8332C2.01258 12.8332 1.73803 12.719 1.50975 12.4908C1.28108 12.2621 1.16675 11.9873 1.16675 11.6665V4.08317C1.16675 3.91789 1.22275 3.77925 1.33475 3.66725C1.44636 3.55564 1.5848 3.49984 1.75008 3.49984C1.91536 3.49984 2.054 3.55564 2.166 3.66725C2.27761 3.77925 2.33341 3.91789 2.33341 4.08317V11.6665H9.91675C10.082 11.6665 10.2205 11.7225 10.3321 11.8345C10.4441 11.9461 10.5001 12.0846 10.5001 12.2498C10.5001 12.4151 10.4441 12.5536 10.3321 12.6652C10.2205 12.7772 10.082 12.8332 9.91675 12.8332H2.33341ZM4.66675 9.33317H11.6667C11.239 8.75956 10.7237 8.32439 10.1209 8.02767C9.51814 7.73134 8.86675 7.58317 8.16675 7.58317C7.46675 7.58317 6.81536 7.73134 6.21258 8.02767C5.6098 8.32439 5.09453 8.75956 4.66675 9.33317Z"
      fill="#3bacf3"
    />
  </svg>
</template>

<script>
export default {};
</script>
