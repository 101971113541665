import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import service from "../services/ApiService.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isActivated: false,
    accessToken: null,
    categories: [],
    user: null,
    role: null
  },
  getters: {
    isAdmin(state) {
      return state.role === "Admin";
    },
    accessToken(state) {
      return state.accessToken;
    },
    user(state) {
      return state.user;
    }
  },
  mutations: {
    USER_LOGIN(state, data) {
      state.accessToken = data.accessToken;
      state.user = data.user;
    },

    USER_LOGOUT(state) {
      state.accessToken = null;
      state.user = null;
      state.role = null;
    }
  },
  actions: {
    async login({ commit }, data) {
      const res = await service.auth.login(data);
      commit("USER_LOGIN", res.data);
    }
  },
  modules: {},
  plugins: [createPersistedState()]
});
