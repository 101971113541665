<template>
  <div class="phrases">
    <div class="q-pa-md">
      <div class="phrases-list q-my-lg">
        <div>
          <div class="flex row q-gutter-md justify-end q-my-md items-center">
            <div class="col-12 col-md-2">
              <q-select
                dropdown-icon="keyboard_arrow_down"
                class="col-12"
                :options="['', 'Morning', 'Afternoon', 'Evening']"
                label="Choose Day Time"
                v-model="dayTime"
              />
            </div>
            <div class="col-12 col-md-2">
              <q-input
                v-model="week"
                placeholder="Week"
                @input="typeaheadDelay(getData)"
                type="number"
              >
                <template v-slot:prepend>
                  <q-icon name="event" />
                </template>
              </q-input>
            </div>
            <div class="col-12 col-md-4">
              <q-input
                class="type-name"
                v-model="search"
                placeholder="Search"
                @input="typeaheadDelay(getData)"
              >
                <template v-slot:prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </div>
          </div>
        </div>
        <q-table
          ref="table"
          :grid="$q.screen.xs"
          :data="data"
          :columns="columns"
          row-key="id"
          borderless
          color="#797979"
          @request="getData"
          :pagination.sync="initialPagination"
          :loading="loading"
        >
          <template v-slot:bottom>
            <pagination
              @change="getData"
              :initialPagination="initialPagination"
              :limits="limits"
            />
          </template>
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>
          <template v-slot:header-cell-actions>
            <th>
              <q-btn
                color="primary"
                class="save-btn"
                text-color="white"
                label="ADD PHRASE"
                @click="manage('create', {})"
              /></th
          ></template>
          <template v-slot:body-cell-phrase="props">
            <td :props="props" width="250">
              <p class="phrase-text">{{ props.row.phrase }}</p>
              <q-tooltip max-width="300px">
                {{ props.row.phrase }}
              </q-tooltip>
            </td>
          </template>
          <template v-slot:body-cell-author="props">
            <td :props="props" width="250" style="cursor: pointer">
              <p
                class="phrase-text text-primary"
                @click="
                  search = props.row.author.name;
                  getData();
                "
              >
                {{ props.row.author.name }}
              </p>
            </td>
          </template>
          <template v-slot:body-cell-actions="props">
            <td :props="props" width="150" class="text-right actions">
              <q-btn @click="manage('update', props.row)" flat>
                <q-icon name="edit"></q-icon>
              </q-btn>
              <q-btn @click="openDeleteModal(props.row)" flat>
                <q-icon name="delete"></q-icon>
              </q-btn>
            </td>
          </template>
        </q-table>
      </div>
    </div>

    <delete-modal
      name="phrase"
      endpoint="phrases"
      :model="deleteModal"
      :data="phrase"
      @close="deleteModal = $event"
      @remove="remove($event)"
    ></delete-modal>
  </div>
</template>
<script>
import { limits } from "@/utils/filter";
import Pagination from "@/components/Pagination.vue";
import DeleteModal from "@/components/modals/DeleteModal";
import { typeaheadDelay } from "@/helpers/typeaheadDelay";

export default {
  components: { Pagination, DeleteModal },
  data() {
    return {
      limits: limits,
      deleteModal: false,
      isHidden: false,
      data: [],
      phrase: {},
      search: null,
      week: null,
      dayTime: null,
      typeaheadDelay: typeaheadDelay,
      columns: [
        {
          name: "id",
          align: "left",
          label: "ID",
          field: "id",
        },
        {
          name: "phrase",
          align: "center",
          label: "Phrase",
          field: "phrase",
          style: "min-width: 500px,",
        },
        {
          name: "author",
          align: "left",
          label: "Author",
          field: (row) => row.author.name,
        },
        {
          name: "week",
          align: "left",
          label: "Week",
          field: "week",
        },
        {
          name: "dayTime",
          align: "left",
          label: "Day Time",
          field: "dayTime",
        },
        {
          name: "actions",
        },
      ],
      loading: false,
      initialPagination: {
        page: 1,
        rowsPerPage: 15,
        rowsNumber: 0,
        pageCount: 1,
        total: 1,
      },
    };
  },
  async created() {
    await this.getData();
  },
  watch: {
    dayTime: function () {
      this.getData();
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      const params = {
        page: this.initialPagination.page,
        limit: this.initialPagination.rowsPerPage,
        search: this.search,
      };

      if (this.week) params["filter.week"] = this.week;
      if (this.dayTime) params["filter.dayTime"] = this.dayTime;

      const res = await this.$http.phrases.get(params);
      this.data = res.data.data;
      this.initialPagination.rowsNumber = res.data.meta.totalItems;
      this.initialPagination.pageCount = res.data.meta.totalPages;
      this.loading = false;
    },
    manage(action, data) {
      this.$router.push(
        action == "update" ? `/phrase/${data.id}` : "/add-phrase"
      );
    },
    openDeleteModal(item) {
      this.phrase = item;
      this.deleteModal = true;
    },
    remove(e) {
      const index = this.data.indexOf(e);
      this.data.splice(index, 1);
    },
  },
};
</script>
